




















































import { Component, Vue } from "vue-property-decorator";
import Navbar from '@/components/common/Navbar.vue'; 
import DataTable from '@/components/common/Tables/DataTable.vue';
import { ILink, ITableHeader } from '@/interfaces/common';
import RequestService from '@/services/RequestService';
import Illustration from '@/components/common/Illustration.vue';
import { IRequests, IRequestItems } from '@/interfaces/request';


@Component({
    components: {
        Navbar,
        DataTable,
        Illustration
        
    }
})

export default class Create extends Vue {
private description: string = "No RequestItems Found" 
private subdescription: string = ""
private showRequestItemList: boolean = true
private RequestItemsLoading: boolean = false
private totalRequestItems: number = 0
private menuItems: Array<ILink> = []
private timeout !: NodeJS.Timeout
private requestItems: Array<IRequestItems> = []
private selectedRequestId: number = 0
private snackbarColor: string = 'success'
private message: string = ''
private snackbar: boolean = false
private pages: number = 0
private requestId: string = ''
private page: number = 1
private sideDrawer: boolean = false
private tableHeaders: Array<ITableHeader> = [
    {
      text: "Shop Name",
      value: "shopName",
      sortable: true,
      default: ''
    },
    {
      text: "Last Order",
      value: "lastOrder",
      sortable: false,
      default: ''
    },
    {
      text: "Last Visit",
      value: "lastVisit",
      sortable: false,
      default: 'NA'
    },
    {
      text: "Number Of Visit",
      value: "noOfVisits",
      sortable: false,
      default: ''
    }
]  

get formValidate(): Vue &{validate: () => boolean}{
            return this.$refs.form as Vue & {validate: () => boolean}
}
get formReset(): Vue &{reset: () => void}{
            return this.$refs.form as Vue & {reset: () => void}
 }
get formResetValidation(): Vue &{resetValidation: () => void}{
            return this.$refs.form as Vue & {resetValidation:() => void}
}
navigateBack()
    {
         this.$router.push({ name: 'DeadOutletApprovalList'})
    }
 public approveRequest(): void{
            RequestService.ActionTakenOnRequest(parseInt(this.requestId), true).then((res) => {
            this.snackbarColor = "success";
            this.message = "Changes Saved Successfully";
            this.$router.push({ name: 'DeadOutletApprovalList'})
           
            }).catch((err: any) => {
            this.showCommunicationError(err);
            console.log(err);
 })
        }
        public disapproveRequest(): void{
             RequestService.ActionTakenOnRequest(parseInt(this.requestId), false).then((res) => {
            this.snackbarColor = "success";
            this.message = "Changes Saved Successfully";
            this.$router.push({ name: 'DeadOutletApprovalList'})
            }).catch((err: any) => {
            this.showCommunicationError(err);
            console.log(err);
 })
        }
public async getOutletRequestItems(requestId:string): Promise<boolean>{
            this.RequestItemsLoading = true;
            this.selectedRequestId = parseInt(requestId);
            return new Promise<boolean>(res => {
                RequestService.GetOutletRequestItems(this.selectedRequestId).then((response) => {
                    this.requestItems = response.data.requestItems;
                    this.pages = response.data.pages;
                    this.totalRequestItems = response.data.total;
                    this.RequestItemsLoading = false;
                    if(this.requestItems.length > 0){
                        this.showRequestItemList = true;
                    }else{
                        this.showRequestItemList = false;
                        this.description = this.requestItems.length == 0 ? "No RequestItems Found" : "" ;
                        this.subdescription = this.requestItems.length == 0 ? "" : "";
                    }
                    res(true);
                }).catch((error) => {
                    this.showCommunicationError(error);
                    console.log(error);
                    this.RequestItemsLoading = false;
                })
            })
        }
         public showCommunicationError(err : any) {    
            this.snackbarColor = "red";
            this.snackbar = true;
            this.message = window.navigator.onLine ? `Some Error occured` : `Cannot reach server at the moment`;
        }

        async created(){
            this.RequestItemsLoading = true;    
            this.requestId = this.$route.params.id;
            this.getOutletRequestItems(this.requestId);
        }
}
